<template>
  <div id="page">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <router-link :to="{ path: '/addapp' }"
          class="btn btn-full btn-m color-white rounded-s gradient-blue border-0 font-700 text-uppercase">
          Create Application <i class="fa fa-plus ms-2"></i>
        </router-link>
      </div>

      <div class="card card-style">
        <div class="content mt-0 mb-0">
          <div class="list-group list-custom-large short-border check-visited">
            <!-- Loading indicator -->
            <div v-if="isLoading" class="list-item">
              Loading...
            </div>

            <!-- No data found message -->
            <div v-else-if="applications.data.length === 0" class="list-item">
              No applications available.
            </div>

            <!-- Application list -->
            <router-link v-for="application in applications.data" :key="application.id"
              :to="{ name: 'SubmittedApplication', params: { id: application.id } }" class="list-item">
              <i :class="getStatusIcon(application.status)"></i>
              <span style="margin-top: 0px !important;">
                Application {{ application.application_number }}
              </span>
              <i class="fa fa-angle-right"></i>
            </router-link>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: 'ApplicationListingPage',
  data() {
    return {
      isLoading: true,  // Track loading state
      applications: {
        data: [],
        links: [],
        current_page: 1,
        last_page: 1
      }
    };
  },
  mounted() {
    this.fetchApplications();
    console.log('Applications after fetch:', this.applications.data); // Check what applications hold
  },
  methods: {
    async fetchApplications() {
      this.isLoading = true;  // Set loading state to true
      try {
        const response = await api.getApplications();
        console.log('Fetched applications:', response.data); // Log the full response

        // If response.data is directly the array of applications
        if (Array.isArray(response.data)) {
          this.applications = {
            data: response.data, // This should be the array of applications
            links: [],
            current_page: 1,
            last_page: 1
          };
        } else {
          console.error('Unexpected response structure:', response.data);
          this.applications.data = []; // Reset data if structure is unexpected
        }

        console.log('Applications after fetch:', this.applications.data); // Verify the applications data
      } catch (error) {
        console.error('Error fetching applications:', error);
      } finally {
        this.isLoading = false;  // Set loading state to false after fetching data
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case 'approved':
          return 'fa fa-check-circle bg-green-dark rounded-m';
        case 'rejected':
          return 'fa fa-times bg-red-dark rounded-m';
        case 'pending':
        default:
          return 'fa fa-exclamation bg-orange-dark rounded-m';
      }
    }
  }
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination-button {
  padding: 5px 10px;
  border: 1px solid #007bff;
  background-color: white;
  color: #007bff;
  cursor: pointer;
  border-radius: 5px;
}

.pagination-button.active {
  background-color: #007bff;
  color: white;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.btn a {
  text-decoration: none;
  color: aliceblue;
}

.btn-add {
  background-color: #C94F57;
  color: white;
  border: none;
  border-radius: 10px;
}

.btn-add i {
  margin-right: 8px;
}
</style>
