<!-- AddApp.vue -->
<template>
  <div id="page">
    <div class="page-content header-clear-small">
      <!-- Heading for Application -->
      <div class="card card-style">
        <div class="content mt-">
          <h1 class="text-3xl font-bold">Application</h1>
        </div>
      </div>

      <!-- Card for Personal and Dependent Details -->
      <div class="card card-style">
        <div class="content mt-3">
          <!-- Personal Details Section -->
          <div class="d-flex">
            <div class="align-self-center">
              <h1 class="font-22">Personal Details</h1>
              <p class="font-11 mt-n2 mb-4">
                {{ personalDetailsStatus.message }}
              </p>
            </div>
            <div class="ms-auto align-self-center">
              <i :class="personalDetailsStatus.icon"></i>
            </div>
          </div>

          <div class="divider mb-3 mt-n3"></div>

          <!-- Dependent Details Section -->
          <div class="d-flex">
            <div class="align-self-center">
              <h1 class="font-22">Dependent Details</h1>
              <p class="font-11 mt-n2 mb-4">
                {{ dependentDetailsStatus.message }}
              </p>
            </div>
            <div class="ms-auto align-self-center">
              <i :class="dependentDetailsStatus.icon"></i>
            </div>
          </div>

          <div class="divider mb-3 mt-n3"></div>

          <!-- Button to Edit Personal Details -->
          <router-link to="/profile"
            class="btn btn-full btn-m mt-3 color-white rounded-s gradient-green border-0 font-700 text-uppercase">
            Edit Personal Details <i class="fa fa-arrow-right ms-2"></i>
          </router-link>
        </div>
      </div>

      <!-- Card for Application Details -->
      <div class="card card-style mt-6">
        <div class="content mt-3">
          <!-- Application Details Section -->
          <div class="d-flex">
            <div class="align-self-center">
              <h1 class="font-22">Application Details</h1>
              <p class="font-11 mt-n2 mb-4">You have not filled details</p>
            </div>
            <div class="ms-auto align-self-center">
              <i class="fa fa-exclamation-circle color-red-dark fa-3x mb-4 me-1"></i>
            </div>
          </div>

          <div class="divider mb-3 mt-n3"></div>

          <router-link :to="{ path: '/appform' }"
            class="btn btn-full btn-m mt-3 color-white rounded-s gradient-blue border-0 font-700 text-uppercase">
            Fill Application Details <i class="fa fa-arrow-right ms-2"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ApplicationListingPage',
  computed: {
    ...mapGetters(['beneficiary', 'dependents']),
    personalDetailsStatus() {
      if (this.beneficiary && Object.keys(this.beneficiary).length > 0) {
        return {
          message: 'You have completed personal details',
          icon: 'fa fa-check-circle color-green-dark fa-3x mb-4 me-1'
        };
      } else {
        return {
          message: 'You have not completed personal details',
          icon: 'fa fa-exclamation-circle color-red-dark fa-3x mb-4 me-1'
        };
      }
    },
    dependentDetailsStatus() {
      if (this.dependents && this.dependents.length > 0) {
        return {
          message: 'You have added dependent details',
          icon: 'fa fa-check-circle color-green-dark fa-3x mb-4 me-1'
        };
      } else {
        return {
          message: 'You have not added any dependents',
          icon: 'fa fa-exclamation-circle color-red-dark fa-3x mb-4 me-1'
        };
      }
    }
  },
  created() {
    this.$store.dispatch('fetchBeneficiary');
    this.$store.dispatch('fetchDependents');
  }
};
</script>

<style>
.page-content {
  padding-bottom: 80px;
}
</style>