<template>
  <div>
    <div id="page">
      <div class="page-content header-clear-small">
        <div class="card card-style">
          <div class="content">
            <div v-if="loading" class="text-center p-3">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <div v-else-if="error" class="alert alert-danger">
              {{ error }}
            </div>

            <div v-else-if="surveyData && currentSection">
              <!-- Section Title -->
              <h4 class="mb-4">{{ currentSection.name }}</h4>

              <!-- Questions -->
              <div v-for="(question, index) in currentSection.questions" :key="question.id" class="question mb-4">
                <h5 class="font-14">
                  <span class="question-number">{{ index + 1 }}.</span>
                  {{ question.question }}
                  <span v-if="question.is_mandatory" class="text-danger">*</span>
                </h5>

                <!-- Radio Inputs -->
                <div v-if="question.type === 'radio'" class="mt-2">
                  <div v-for="option in question.question_options" :key="option.id"
                    class="form-check interest-check scale-switch">
                    <input class="form-check-input" type="radio" :id="`${question.id}-${option.id}`"
                      :name="`question-${question.id}`" :value="option.value"
                      :checked="answers[question.id] === option.value"
                      @change="handleAnswer(question.id, option.value)" />
                    <label class="form-check-label rounded-m" :for="`${question.id}-${option.id}`">
                      {{ option.option }}
                    </label>
                  </div>
                </div>

                <!-- Select Inputs -->
                <div v-else-if="question.type === 'select'" class="input-style has-borders no-icon validate-field mb-4">
                  <select class="form-control validate-select" :value="answers[question.id]"
                    @change="handleAnswer(question.id, $event.target.value)">
                    <option value="" disabled selected>-- Select --</option>
                    <option v-for="option in question.question_options" :key="option.id" :value="option.value">
                      {{ option.option }}
                    </option>
                  </select>
                </div>

                <!-- Checkbox Inputs -->
                <div v-else-if="question.type === 'checkbox'" class="mt-2">
                  <div v-for="option in question.question_options" :key="option.id"
                    class="form-check interest-check scale-switch">
                    <input class="form-check-input" type="checkbox" :id="`${question.id}-${option.id}`"
                      :value="option.value" :checked="isCheckboxChecked(question.id, option.value)" @change="
                        handleCheckboxChange(question.id, option.value, $event)
                        " />
                    <label class="form-check-label rounded-m" :for="`${question.id}-${option.id}`">
                      {{ option.option }}
                    </label>
                  </div>
                </div>

                <!-- Number Inputs -->
                <div v-else-if="question.type === 'number'" class="input-style has-borders no-icon validate-field mb-4">
                  <input type="number" class="form-control" :value="answers[question.id]"
                    @input="handleAnswer(question.id, $event.target.value)" :placeholder="question.question" />
                </div>
              </div>

              <!-- Navigation Buttons -->
              <div class="mt-4 d-flex justify-content-between">
                <button v-if="currentSectionIndex > 0" @click="previousSection" class="btn btn-secondary">
                  Previous
                </button>

                <button v-if="currentSectionIndex < surveyData.sections.length - 1" @click="nextSection"
                  class="btn btn-primary">
                  Next
                </button>

                <button v-else @click="handleSubmit" class="btn btn-success">
                  അപേക്ഷ സമർപ്പിക്കുക
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import apiService from "@/services/api";

export default {
  name: "SurveyForm",

  setup() {
    const router = useRouter();
    const loading = ref(true);
    const error = ref(null);
    const surveyData = ref(null);
    const currentSectionIndex = ref(0);
    const answers = ref({});
    const submissionId = ref(null);

    // Computed property for current section
    const currentSection = computed(() => {
      if (!surveyData.value || !surveyData.value.sections.length === 0) return null;
      return surveyData.value.sections[currentSectionIndex.value];
    });

    // Load survey data
    const loadSurveyForm = async () => {
      try {
        loading.value = true;
        const response = await apiService.getSurveyForm(1);
        surveyData.value = response.data;
        
        // Initialize answers object with proper question IDs
        const initialAnswers = {};
        response.data.sections.forEach(section => {
          section.questions.forEach(question => {
            // For radio buttons, initialize as null
            // For checkboxes, initialize as empty array
            initialAnswers[question.id] = question.type === 'checkbox' ? [] : null;
          });
        });
        answers.value = initialAnswers;
        
        loading.value = false;
      } catch (err) {
        error.value = "Failed to load survey form: " + (err.response?.data?.message || err.message);
        loading.value = false;
      }
    };

    // Handle answer changes with proper question ID
    const handleAnswer = (questionId, value) => {
      const numericQuestionId = parseInt(questionId);
      answers.value[numericQuestionId] = value;
    };

    // Handle checkbox changes with proper question ID
    const handleCheckboxChange = (questionId, value, event) => {
      const numericQuestionId = parseInt(questionId);
      
      if (!answers.value[numericQuestionId]) {
        answers.value[numericQuestionId] = [];
      }

      const currentAnswers = [...answers.value[numericQuestionId]];
      
      if (event.target.checked) {
        if (!currentAnswers.includes(value)) {
          currentAnswers.push(value);
        }
      } else {
        const index = currentAnswers.indexOf(value);
        if (index > -1) {
          currentAnswers.splice(index, 1);
        }
      }
      
      answers.value[numericQuestionId] = currentAnswers;
    };

    // Check if checkbox is checked with proper question ID
    const isCheckboxChecked = (questionId, value) => {
      const numericQuestionId = parseInt(questionId);
      return Array.isArray(answers.value[numericQuestionId]) && 
             answers.value[numericQuestionId].includes(value);
    };

    const handleSubmit = async () => {
      try {
        loading.value = true;
        error.value = null;

        // Format answers for submission
        const formattedAnswers = Object.entries(answers.value)
          .filter(([, value]) => {
            if (Array.isArray(value)) {
              return value.length > 0;
            }
            return value !== undefined && value !== null && value !== "";
          })
          .map(([questionId, value]) => {
            let formattedValue = value;
            if (Array.isArray(value)) {
              formattedValue = value.join(",");
            } else if (typeof value === 'boolean') {
              formattedValue = value ? "1" : "0";
            } else {
              formattedValue = String(value);
            }

            return {
              question_id: parseInt(questionId),
              value: formattedValue
            };
          });

        // Submit the form
        const response = await apiService.submitSurveyForm(formattedAnswers);

        if (response.data.status === "success" && response.data.submission_id) {
          submissionId.value = response.data.submission_id;
          
          // Store submission response
          localStorage.setItem('lastSubmissionId', response.data.submission_id);
          localStorage.setItem('lastSubmissionData', JSON.stringify(response.data.formData));

          // Navigate to confirmation page
          router.push({
            name: "SubmittedApplication",
            params: { id: response.data.submission_id },
          });
        } else {
          throw new Error(response.data.message || "Submission failed");
        }
      } catch (err) {
        console.error("Form submission error:", err);
        error.value = err.response?.data?.message || err.message || "Failed to submit form";
      } finally {
        loading.value = false;
      }
    };

    // Navigation methods
    const nextSection = () => {
      if (currentSectionIndex.value < surveyData.value.sections.length - 1) {
        currentSectionIndex.value++;
      }
    };

    const previousSection = () => {
      if (currentSectionIndex.value > 0) {
        currentSectionIndex.value--;
      }
    };

    onMounted(() => {
      loadSurveyForm();
    });

    return {
      loading,
      error,
      surveyData,
      currentSection,
      currentSectionIndex,
      answers,
      submissionId,
      handleAnswer,
      handleCheckboxChange,
      isCheckboxChecked,
      nextSection,
      previousSection,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.interest-check label {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-bottom: 5px;
}

.interest-check label:hover {
  background-color: #f8f9fa;
}

.form-check-input:checked+label {
  background-color: #d1ecf1;
  border-color: #0c5460;
  color: #0c5460;
}

.question-number {
  font-weight: bold;
  margin-right: 5px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
