<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div class="content mb-0">
        <h3 class="login-title">Login</h3>
        <form @submit.prevent="onLogin">
          <!-- General Error Message -->
          <div v-if="generalError" class="error general-error">
            {{ generalError }}
          </div>

          <!-- user_name Field -->
          <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
            <label for="user_name" class="color-blue-dark font-600 text-uppercase">User name</label>
            <input type="text" id="user_name" v-model="user_name" class="form-control" placeholder="Enter your username" />
            <span class="error" v-if="errors.user_name">{{ errors.user_name }}</span>
          </div>

          <!-- Password Field -->
          <div class="input-style has-borders no-icon validate-field mb-4 input-style-active">
            <label for="password" class="color-blue-dark font-600 text-uppercase">Password</label>
            <input type="password" id="password" v-model="password" class="form-control" placeholder="Enter your password" />
            <span class="error" v-if="errors.password">{{ errors.password }}</span>
          </div>

          <hr>

          <!-- Login Button -->
          <button type="submit" :disabled="isLoading" class="btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-blue-dark">
            <span v-if="isLoading">Logging in...</span>
            <span v-else>Login</span>
          </button>
        </form>
      </div>
    </div>
    <p class="signup-link">Don't have an account? <router-link :to="{ path: '/signup' }">Sign up</router-link></p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      user_name: '',
      password: '',
      errors: {},
      generalError: null,
      isLoading: false
    };
  },
  methods: {
    ...mapActions(['login']),
    async onLogin() {
      this.errors = {};
      this.generalError = null;
      this.isLoading = true;

      if (!this.user_name) this.errors.user_name = 'Username is required';
      if (!this.password) this.errors.password = 'Password is required';

      if (Object.keys(this.errors).length > 0) {
        this.isLoading = false;
        return;
      }

      try {
        const loginSuccess = await this.login({ user_name: this.user_name, password: this.password });
        if (!loginSuccess) {
          this.generalError = 'Login failed. Please check your credentials and try again.';
        }
        // If login is successful, the store action will handle the navigation
      } catch (error) {
        console.error('Login error:', error);
        this.generalError = 'An unexpected error occurred. Please try again.';
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.general-error {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.error {
  color: #c62828;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.login-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.input-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.input-group {
  width: 100%;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #0056b3;
}

.signup-link {
  text-align: center;
  font-size: 0.875rem;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.error {
  color: red;
}
</style>