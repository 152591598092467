import axios from "axios";

const API_URL = "https://zakath.cloudocz.com/api";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to inject the token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error is due to an unauthorized access (401)
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized access - maybe token is invalid");
      localStorage.removeItem("token"); // Clear token
      window.location.href = "/login"; // Redirect to login
    }
    return Promise.reject(error);
  }
);

const apiService = {
  // Auth methods
  login(credentials) {
    return api.post("/login", credentials);
  },
  signup(userData) {
    return api.post("/register", userData);
  },

  // Dependent methods
  createDependent(dependentData) {
    return api.post("/dependents", dependentData);
  },
  getDependents() {
    return api.get("/dependents");
  },
  updateDependent(id, dependentData) {
    return api.patch(`/dependents/${id}`, dependentData);
  },
  deleteDependent(id) {
    return api.delete(`/dependents/${id}`);
  },

  // Beneficiary methods
  getBeneficiaries() {
    return api.get("/beneficiaries");
  },
  createBeneficiary(beneficiaryData) {
    return api.post("/beneficiaries", beneficiaryData);
  },
  updateBeneficiary(id, beneficiaryData) {
    return api.put(`/beneficiaries/${id}`, beneficiaryData);
  },
  deleteBeneficiary(id) {
    return api.delete(`/beneficiaries/${id}`);
  },

  getSurveyForm(id) {
    return api.get(`/survey-forms/${id}`); // Updated endpoint
  },

  submitSurveyForm(formData) {
    // Only format the answers that have values
    const cleanedFormData = formData.filter(answer => {
      return answer.question_id && answer.value !== undefined && answer.value !== null;
    }).map(answer => ({
      question_id: parseInt(answer.question_id),
      value: String(answer.value)
    }));

    return api.post("/survey-forms", cleanedFormData);
  },

  // Application methods
  getApplications() {
    return api.get("/applications");
  },
  getApplicationById(id) {
    return api.get(`/applications/${id}`);
  },
  async createApplicationWithFiles(applicationData) {
    try {
      const response = await api.post("/applications", applicationData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.error('Error creating application:', error);
      throw error;
    }
  },
  async updateApplicationWithFiles(id, applicationData) {
    try {
      // Add _method field for Laravel to handle PUT request with FormData
      applicationData.append('_method', 'PUT');
      
      const response = await api.post(`/applications/${id}`, applicationData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.error('Error updating application:', error);
      throw error;
    }
  },
  deleteApplication(id) {
    return api.delete(`/applications/${id}`);
  },

  async updateApplicationDocuments(id, formData) {
    return api.post(`/applications/${id}/update-documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async deleteDocument(id) {
    return api.delete(`/documents/${id}`);
  }
};

export default apiService;
