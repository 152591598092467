<!-- SubmitApplication.vue -->
<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div v-if="isSubmitted" class="submitted-application">
        <div class="content">
          <h1>Application: {{ application.application_number }}</h1>
          <div class="divider mb-3"></div>

          <!-- JCC Beneficiary Status -->
          <div class="info-section">
            <h5 class="font-14">1. JCC യുടെ സഹായം</h5>
            <p>{{ application.existing_beneficiary === 1 ? 'ഉണ്ട്' : 'ഇല്ല' }}</p>
          </div>

          <div class="divider mb-3"></div>
          <!-- JCC Benefits Details -->
          <div v-if="application.existing_beneficiary === 1" class="info-section">
            <h5 class="font-14">2. സഹായം ലഭിക്കുന്ന വിങ്ങും തുകയും</h5>
            <div class="d-flex" style="gap: 10px;">
              <p><strong>വിങ്:</strong> {{ application.wing_benefiting_from }}</p>
              <p><strong>തുക:</strong> {{ application.amount_benefiting_from }}</p>
            </div>
          </div>

          <div class="divider mb-3"></div>
          <!-- House Details -->
          <div class="info-section">
            <h5 class="font-14">3. നിലവിൽ വീട്</h5>
            <p>{{ getHouseTypeLabel(application.house_type) }}</p>
          </div>

          <div class="divider mb-3"></div>
          <!-- Category -->
          <div class="info-section">
            <h5 class="font-14">4. സക്കാത്ത് സഹായത്തിന് അപേക്ഷിക്കുന്നത്</h5>
            <p>{{ application.category }}</p>
          </div>

          <div class="divider mb-3"></div>
          <!-- Documents -->
          <div class="info-section">
            <h5 class="font-14">ഡോക്യുമെന്റുകൾ</h5>
            <div v-if="application.documents && application.documents.length > 0">
              <div v-for="(doc, index) in application.documents" :key="index" class="document-item">
                {{ index + 1 }}. {{ getDocumentName(doc.path) }}
              </div>
            </div>
            <p v-else>No documents uploaded</p>
          </div>

          <hr>
          <!-- Action Buttons -->
          <div class="button-group d-flex" style="gap: 10px;">
            <button @click="editApplication"
              class="btn btn-m btn-full rounded-xs text-uppercase font-900 shadow-s bg-dark-light">
              Edit
            </button>
            <button @click="confirmDelete"
              class="btn btn-m btn-full rounded-xs text-uppercase font-900 shadow-s bg-red-dark">
              Delete
            </button>
          </div>
        </div>
      </div>

      <!-- Loading State -->
      <div v-else class="text-center p-3">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: ['id'],

  data() {
    return {
      application: {},
      isSubmitted: false,
      houseTypes: {
        'Rented': 'വാടക വീട്ടിൽ',
        'Owned': 'സ്വാന്തം പേരിൽ',
        'Lease': 'പാട്ടത്തിന്',
        'Extended Family': 'കൂട്ടുകുടുംബം',
        'Sibling House': 'സഹോദരന്റെ/സഹോദരിയുടെ വീട്ടിൽ',
        'mortgage': 'പണയം',
        'other': 'മറ്റുള്ളവ'
      }
    };
  },

  created() {
    // Add route watcher
    this.$watch(
      () => this.$route.params.id,
      (newId) => {
        if (newId) {
          this.fetchApplication();
        }
      }
    );
  },

  mounted() {
    this.fetchApplication();
  },

  methods: {
    ...mapActions(['setEditingApplication']),

    getHouseTypeLabel(type) {
      return this.houseTypes[type] || type;
    },

    getDocumentName(path) {
      return path.split('/').pop();
    },

    async fetchApplication() {
      try {
        this.isSubmitted = false;
        const response = await api.getApplicationById(this.id);

        // Clear any cached data
        this.application = {};

        // Force a tick to ensure Vue updates the UI
        await this.$nextTick();

        if (Array.isArray(response.data) && response.data.length > 0) {
          this.application = response.data[0];
        } else if (response.data) {
          this.application = response.data;
        }

        // Force another tick to ensure the new data is rendered
        await this.$nextTick();
        this.isSubmitted = true;
      } catch (error) {
        console.error('Error fetching application:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to load application data. Please try again.',
          icon: 'error'
        });
        this.isSubmitted = true;
      }
    },

    editApplication() {
      this.setEditingApplication(this.application);
      this.$router.push({ name: 'EditApplication', params: { id: this.id } });
    },

    async confirmDelete() {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this application?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6'
      });

      if (result.isConfirmed) {
        this.deleteApplication();
      }
    },

    async deleteApplication() {
      try {
        await api.deleteApplication(this.id);
        Swal.fire({
          title: 'Success!',
          text: 'Application deleted successfully',
          icon: 'success'
        });
        this.$router.push('/features');
      } catch (error) {
        console.error('Error deleting application:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete application. Please try again.',
          icon: 'error'
        });
      }
    }
  }
};
</script>

<style scoped>
.info-section {
  margin-bottom: 10px;
}

.document-item {
  margin-bottom: 8px;
}

.button-group {
  margin-top: 20px;
}

.button-group .btn {
  flex: 1;
}

.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}
</style>